import { graphql } from 'gatsby';
import React from 'react';

import Footer from '../components/footer';
import PlanHeader from '../components/plan-header';
import HP_Right_Image from '../images/HP_Right_Image.svg';
import pacific_life from '../images/images/pacific-life.jpg';
import righttick from '../images/images/righttick.png';

// Section wise plan page data query
/* export const pageQuery = graphql`
query MyQuery {
  allStrapiPageData(filter: {page_name: {eq: "life_plan_page"}, data_category: {eq: "section_1"}, version: {eq: "v3"}, status: {eq: "Test3"}}) {
    edges {
      node {
        id
        attribute_1_value
        attribute_2_value
        column
        content
        attribute_1_key
        attribute_2_key
        style_category
      }
    }
  }
 }
`
*/

export const pageQuery = graphql`
query MyQuery {
  allStrapiPageData(filter: {page_name: {eq: "life_plan_page"}, version: {eq: "v3"}, status: {eq: "Test3"}}) {
    edges {
      node {
        id
        attribute_1_value
        attribute_2_value
        column
        content
        attribute_1_key
        attribute_2_key
        style_category
        data_category
      }
    }
  }
 }
`;

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = [];
  for ( let i = 0; i < data.length; i++ ) {
    if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
      uniqueSectionArray.push( data[i].node.data_category );
    }

  }
  return uniqueSectionArray;
};

let getformattedData = ( data, sectionsArray ) => {
  let output = {};
  let section;
  for ( let i = 0; i < sectionsArray.length; i++ ) {
    output[sectionsArray[i]] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionsArray[i] && item.node );
    for ( let col = 1; col < 5; col++ ) {

      output[sectionsArray[i]]['column_' + col] = section.filter( ( item ) => item.node.column == col && item.node );
    }
  }
  console.log( 'output: ', output );
  return output;
};

let getformattedData2 = ( data, sectionsArray ) => {
  let output = {};
  let section;
  let column;
  for ( let i = 0; i < sectionsArray.length; i++ ) {
    output[sectionsArray[i]] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionsArray[i] && item.node );
    for ( let col = 1; col < 5; col++ ) {
      output[sectionsArray[i]]['column_' + col] = {};
      column = section.filter( ( item ) => item.node.column == col && item.node );
      for ( let j = 0; j < column.length; j++ ) {
        output[sectionsArray[i]]['column_' + col][column[j].node.style_category] = column[j].node;
      }
    }
  }

  return output;
};

const PlanPage = ( { data } ) => {
  let pageData = data.allStrapiPageData.edges;
  let uniqueSectionData = getUniqueSectionData( pageData );
  // let fmtData = getformattedData(pageData, uniqueSectionData);
  let fmtData2 = getformattedData2( pageData, uniqueSectionData );

  return (
    <div>

      <div className="container" style={{ marginTop: '100px' }}>
        <div className="row">
          <div className="life-insurance-container">
            <img className="kelly" src={fmtData2.section_1.column_2.banner_image.attribute_1_value} alt="kelly"/>

            <div className="life-insurance-details">
              <div className="life-insurance">
                <img src={fmtData2.section_1.column_1.banner_icon.attribute_1_value} alt="life insurance"/>

                <h1 className="life-insurance-heading">{fmtData2.section_1.column_1.banner_title.content}</h1>
              </div>

              <p className="life-insurance-para">
                {fmtData2.section_1.column_1.banner_text.content}
              </p>

              <button className="carrier-plan-button btn-text-white">
                {fmtData2.section_1.column_1.orange_button_link.content}</button>

              <button className="carrier-plan-explore btn-text-orange">
                <a href="#start-exploring" style={{ color: '#ff7007' }}>
                  {fmtData2.section_1.column_1.white_button_link.content}
                </a>

              </button>
            </div>
          </div>

        </div>
      </div>

      <PlanHeader />

      <div className="container container-padding" id="start-exploring">
        <div className="card inner-container">
          <div className="container-border2"></div>

          <div className="card-body" style={{ flexGrow: '1' }}>
            <div className="row">
              <div className="col-lg-4">
                <img src={HP_Right_Image} className="life-insurance-container-img" alt="life insurance"/>

                <h3 className="column1-text">{fmtData2.section_2.column_1.title1.content}</h3>
              </div>

              <div className="col-lg-4">
                <p className="column2-text">
                  Life insurance provides a tax-free lump sum of money, called the
                  death benefit, to a designated beneficiary after your death. You
                  choose the type of policy you need, number of years you want it to
                  last, and coverage amount, and make premium payments to the
                  insurance company.
                  {fmtData2.section_2.column_2.text.content}
                </p>
              </div>

              <div className="col-lg-4">
                <p className="column2-text">
                  Life insurance coverage offers affordable financial protection — and
                  invaluable peace of mind — to people whose friends or family members
                  rely on them.
                  {fmtData2.section_2.column_3.text.content}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* */}

      <div className="card-bg-containers">
        <div className="container container-padding">
          <h3 className="middle-container-heading">
            What does Life Insurance cover?
          </h3>

          <div className="row">
            <div className="col-lg-6 pr-lg-2 pr-0">
              <div className="card inner-container mb-3 mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="container-border"></div>

                <div className="card-body">
                  <h4 className="inner-container-heading">
                    Natural Death or caused by Health-Related Issues
                  </h4>

                  <p className="inner-container-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 pl-lg-2 pr-0">
              <div className="card inner-container mb-3  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="container-border"></div>

                <div className="card-body">
                  <h4 className="inner-container-heading">
                    Natural Death or caused by Health-Related Issues
                  </h4>

                  <p className="inner-container-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 pr-lg-2 pr-0">
              <div className="card inner-container mb-3 mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="container-border"></div>

                <div className="card-body">
                  <h4 className="inner-container-heading">
                    Natural Death or caused by Health-Related Issues
                  </h4>

                  <p className="inner-container-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 pl-lg-2 pr-0">
              <div className="card inner-container mb-3 mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="container-border"></div>

                <div className="card-body">
                  <h4 className="inner-container-heading">
                    Natural Death or caused by Health-Related Issues
                  </h4>

                  <p className="inner-container-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Policies*/}

      <div className="container-padding container">
        <h3 className="policy-container-heading">Our Policies</h3>

        <div className="row">
          <div className="col-lg-4 mb-4 mb-lg-0 pr-0">
            <div className="card policy-container  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
              <div className="card-header policy-card-header">
                <img src={pacific_life} style={{ width: '25%', marginBottom: '8px' }} alt="pacific life"/>

                <h4 className="policy-card-title">Pacific Life Next</h4>
              </div>

              <div className="card-body">
                <div className="card-body-height">
                  <div className="policy-card-row">
                    <div className="policy-card-column">
                      <p className="policy-card-row-detail1">
                        <small>Term Length</small>
                      </p>

                      <p className="policy-card-row-detail2"> 15 Years </p>
                    </div>

                    <div className="policy-card-column">
                      <p className="policy-card-row-detail1 pl-3">
                        <small>Coverage Amt</small>
                      </p>

                      <p className="policy-card-row-detail2 pl-3"> $10 Million </p>
                    </div>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                      {' '}

                    One policy that keeps you covered to age 65 (typically when your mortgage is paid off and the kids are out of the house.){' '}
                    </p>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                      {' '}
                    Buy what you need today and increase or decrease your coverage with a simple process and no additional underwriting.
                    </p>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                    Innovative health lock feature allows you to add coverage in the future based on your original health rating.
                    </p>
                  </div>
                </div>

                <div className="policy-card-btn-div">
                  <a href="/next-by-pacific-life" className="policy-card-button">Learn More</a>

                  {/* <button className="policy-card-button">Learn More</button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 mb-4 mb-lg-0 pr-0">
            <div className="card policy-container mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
              <div className="card-header policy-card-header">
                <img src={pacific_life} style={{ width: '25%', marginBottom: '8px' }} alt="pacific life"/>

                <h4 className="policy-card-title">SBLI Term</h4>
              </div>

              <div className="card-body">
                <div className="card-body-height">
                  <div className="policy-card-row">
                    <div className="policy-card-column">
                      <p className="policy-card-row-detail1">
                        <small>Term Length</small>
                      </p>

                      <p className="policy-card-row-detail2"> 10 Years </p>
                    </div>

                    <div className="policy-card-column">
                      <p className="policy-card-row-detail1 pl-3">
                        <small>Coverage Amt</small>
                      </p>

                      <p className="policy-card-row-detail2 pl-3"> $25 Million </p>
                    </div>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                      {' '}

                    You are covered till you are 45 (based on your current age){' '}
                    </p>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                      {' '}
                    Guaranteed premiums that stay the same for the selected initial term period.
                    </p>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                    Convenient payment methods: credit/debit or bank draft.
                    </p>
                  </div>
                </div>

                <div className="policy-card-btn-div">
                  <a href="/SBLI" className="policy-card-button">Learn More</a>

                  {/* <button className="policy-card-button">Learn More</button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 pr-0">
            <div className="card policy-container  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
              <div className="card-header policy-card-header">
                <img src={pacific_life} style={{ width: '25%', marginBottom: '8px' }} alt="pacific life"/>

                <h4 className="policy-card-title">Symetra Swift Term</h4>
              </div>

              <div className="card-body">
                <div className="card-body-height">
                  <div className="policy-card-row">
                    <div className="policy-card-column">
                      <p className="policy-card-row-detail1">
                        <small>Term Length</small>
                      </p>

                      <p className="policy-card-row-detail2"> 15 Years </p>
                    </div>

                    <div className="policy-card-column">
                      <p className="policy-card-row-detail1 pl-3">
                        <small>Coverage Amt</small>
                      </p>

                      <p className="policy-card-row-detail2 pl-3"> $25 Million </p>
                    </div>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                      {' '}

                    You are covered till you are 40 (based on your current age){' '}
                    </p>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                      {' '}
                    Donec commodo, ex quis eleifend rutrum, enim nunc lacinia ligula, ut pulvinar nulla ex a turpis.
                    </p>
                  </div>

                  <div className="policy-card-row-detail3">
                    <img src={righttick} className="img-responsive" alt="tick"/>

                    <p className="policy-card-row-para pl-3">
                    Cras in pulvinar justo. Nam sit amet semper nisl.
                    </p>
                  </div>
                </div>

                <div className="policy-card-btn-div">
                  <a href="/stillwater" className="policy-card-button">Learn More</a>

                  {/* <button className="policy-card-button">Learn More</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Partners*/}

      <div className="card-bg-containers container-padding">
        <div className="container">
          <h3 className="policy-container-heading">Our Partners</h3>

          <div className="row">
            <div className="col-lg-4 mb-4 mb-lg-0 pr-0">
              <div className="card partners-card  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="card-body">
                  <div className="partners-card-details1">
                    <img src={pacific_life} className="partners-card-img" alt="pacific life"/>

                    <h4 className="partners-card-heading">Pacific Life</h4>
                  </div>

                  <p className="partners-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>

                  <div className="partners-card-details2">
                    <div className="rating-rectangle">
                      <p className="rating-rectangle-para">AA-</p>
                    </div>

                    <p className="partners-card-para">S&amp;P Global</p>
                  </div>

                  <div className="partners-card-details2">
                    <div className="rating-rectangle">
                      <p className="rating-rectangle-para">A+</p>
                    </div>

                    <p className="partners-card-para">A.M Best</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0 pr-0">
              <div className="card partners-card  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="card-body">
                  <div className="partners-card-details1">
                    <img src={pacific_life} className="partners-card-img" alt="pacific life"/>

                    <h4 className="partners-card-heading">Pacific Life</h4>
                  </div>

                  <p className="partners-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>

                  <div className="partners-card-details2">
                    <div className="rating-rectangle">
                      <p className="rating-rectangle-para">AA-</p>
                    </div>

                    <p className="partners-card-para">S&amp;P Global</p>
                  </div>

                  <div className="partners-card-details2">
                    <div className="rating-rectangle">
                      <p className="rating-rectangle-para">A+</p>
                    </div>

                    <p className="partners-card-para">A.M Best</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pr-0">
              <div className="card partners-card  mr-3 mr-md- 0 mr-sm- 0 mr-lg-0">
                <div className="card-body">
                  <div className="partners-card-details1">
                    <img src={pacific_life} className="partners-card-img" alt="pacific life"/>

                    <h4 className="partners-card-heading">Pacific Life</h4>
                  </div>

                  <p className="partners-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                    tincidunt commodo bibendum. Maecenas nec pulvinar nisi.
                  </p>

                  <div className="partners-card-details2">
                    <div className="rating-rectangle">
                      <p className="rating-rectangle-para">AA-</p>
                    </div>

                    <p className="partners-card-para">S&amp;P Global</p>
                  </div>

                  <div className="partners-card-details2">
                    <div className="rating-rectangle">
                      <p className="rating-rectangle-para">A+</p>
                    </div>

                    <p className="partners-card-para">A.M Best</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="plansForYou">
        <div className="orangeBackGround">
          <div>
            See which plan is perfect for you
          </div>

          <div className="customizedPlan">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas non suscipit purus, a sodales enim. Aliquam in nisl laoreet, blandit nisi eu, convallis libero.
          </div>

          <button  type="button" className="btnWhite">Get your quote now</button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PlanPage;
